import React from "react"
import styled from "styled-components"
import LeafBorder from "../../images/hand-painted-leaf-border-cut.png"
import Layout from "../../components/layout-canada"
import SEO from "../../components/seo"

const LeafBorderPicture = styled.div`
  background-image: url("${LeafBorder}");
  height: 30px;
  width: 80px;
  margin-bottom: 32px;
  background-size: 80px 30px;
`
const H1 = styled.h1`
  margin-bottom: 6px;
`

const P = styled.p`
  font-family: "Benton Sans";
  text-align: justify;
`

const Ul = styled.ul`
  font-family: "Benton Sans";
`

class GiftsPage extends React.Component {
  render() {
    return (
      <Layout>
        <SEO title="Gifts | Sara & Tim" />
        <H1>GIFTS</H1>
        <LeafBorderPicture />
        <P>
          Thank you so much to everyone who already contributed towards our
          honeyfund - we have planned a much needed honeymoon getaway to Mexico
          in May!
        </P>
        <P>
          Given the events over the last 2 years, just having you at our
          celebration would be the best gift we could imagine!
        </P>
        <P>
          If you insist, any donations to the following charities would be
          greatly appreciated.
        </P>
        <Ul>
          <li>
            <a href="https://kelownagospelmission.ca/donate/">
              Kelowna Gospel Mission
            </a>
          </li>
          <li>
            <a href="http://lprc.ca/donate/">Living Positive</a>
          </li>
        </Ul>
        <P>
          Or you could offset your travel to Kelowna using a service like{" "}
          <a href="https://app.planetair.ca/?lang=english">Planetair</a>.
        </P>
      </Layout>
    )
  }
}

export default GiftsPage
